@import "~bootstrap/scss/bootstrap"
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap')

$haBlue: #93daf8
$Large: 'only screen and (max-width: 927px)'
$Medium: 'only screen and (max-width: 687px)'
$Small: 'only screen and (max-width: 500px)'
$XtraSmall: 'only screen and (max-width: 370px)'

*
  box-sizing: border-box

.mission-statement
  font-style: italic
  font-weight: 700

.master-container
  background-color: #dde2e4

  .about-description
    font-family: 'Lato', sans-serif


  .header-container
    width: 100%
    padding: .75rem 2rem
    display: flex
    align-items: center
    justify-content: space-between
    background-color: #000
    position: fixed
    z-index: 2

    .header-left
      button
        border: none
        background-color: inherit
        padding: 0

        &:focus
          outline: none

        .logo
          width: 10rem
          background: transparent
          pointer-events: auto
      
      #bbb
        width: 2.5rem
        margin-left: 1.5rem
        
      


    .header-right
      background-color: #000
      display: flex
      align-items: center

      .burger-bag
        display: none

      .links
        color: #fefefe
        font-family: 'Lato', sans-serif
        transition: .3s
        margin-left: 3rem
        font-size: 1.1rem

        &:hover
          color: $haBlue
          text-decoration: none

      .icon-container
        display: flex
        align-items: center

        .icon
          color: $haBlue
          font-size: 2rem
          margin-left: 3rem
          margin-right: 1rem

        .number-description
          display: flex
          flex-direction: column
          align-items: center

          .free-quote
            font-size: .8rem
            color: $haBlue
            margin: 0

          .phone-number
            margin-left: 0

    //941px MEDIA QUERY
    @media only screen and (max-width: 1020px)
      .header-left
        button
          .logo
            width: 8rem

      .header-right
        .links
          font-size: 1rem

        .icon-container
          .icon
            font-size: 1.8rem

          .number-description
            .free-quote
              font-size: .6rem

    @media #{$Large}
      display: block

      .header-left
        display: flex
        justify-content: center

      .header-right
        display: flex
        justify-content: center

        .links
          &:nth-child(1)
            margin-left: 0

    @media #{$Medium}
      display: flex
      z-index: 12

      .header-left

      .header-right

        .links
          display: none

        .icon-container
          display: none

        .burger-bag
          display: block

          .burger
            width: 40px
            height: 4px
            border-radius: 5px
            background-color: #fefefe
            transform: translateX(0px)
            transition: .25s

            &::before, &::after
              content: ''
              position: absolute
              width: 40px
              height: 4px
              background-color: #fefefe
              border-radius: 5px
              transition: .25s ease-in-out

            &::before
              transform: rotate(0deg) translateY(-12px)

            &::after
              transform: rotate(0deg) translateY(12px)

            &.burger--ex
              transform: translateX(-50px)
              background-color: transparent

              &::before
                transform: rotate(45deg) translate(35px, -35px)

              &::after
                transform: rotate(-45deg) translate(35px, 35px)

    @media #{$Small}
      padding: 1rem

  .slides-container
    height: calc(100vh - 9rem)
    overflow: hidden

    @media only screen and (max-width: 1009px) , only screen and (min-height: 854px)
      height: auto

    .carousel-indicators
      display: none

    .carousel-control-prev, .carousel-control-next
      display: none

    .carousel-inner
      .carousel-item
        position: relative

        img
          width: 100%

        .review-container
          position: absolute
          top: calc((100vh - 9rem) / 4)
          left: 6rem
          width: 30rem
          background-color: #121212
          border-radius: 5px
          display: flex
          align-items: center
          flex-direction: column
          padding: 2rem
          box-shadow: -8px 8px $haBlue
          font-family: 'Lato', sans-serif
          font-size: 1.1rem

          .description
            color: #fefefe

          .name
            color: $haBlue
            margin-left: 18rem

          .stars-container
            padding-bottom: 1rem

            .stars
              color: #faff06

          .see-reviews
            color: #fefefe
            transition: .3s

            &:hover
              color: $haBlue

    @media #{$Large}

      .carousel-inner
        .carousel-item
          img
            height: 80vh
            width: auto
          .review-container
            font-size: 1rem
            width: 20rem
            padding: 1.5rem

            .name
              margin-left: 10rem

    @media #{$Medium}
      .carousel-inner
        .carousel-item
          .review-container
            top: 60%
            left: 50%
            transform: translate(-50%, -50%)
            width: 17rem
            padding: 1rem

            .description

            .name
              margin-left: 8rem

    @media #{$Small}
      .carousel-inner
        .carousel-item
          img
            height: 60vh

          .review-container
            top: 60%
            left: 50%
            transform: translate(-50%, -50%)
            width: 18rem
            padding: 1rem
            font-size: .9rem

            .description

            .name
              margin-left: 8rem

  .about-container
    background-color: #dde2e4
    padding: 5rem 2rem 0 2rem

    h1
      text-align: center
      margin: 0 auto 5rem auto
      max-width: 75rem
      font-family: 'Open Sans', sans-serif
      font-weight: bold
      color: #202020

    .service-header
      margin: 3rem

    .why-header
      margin: 3rem 0 1rem 0
      text-align: center

    .services-container
      max-width: 60rem
      font-size: 1.1rem
      margin: 0 auto

      @media #{$Large}
      padding: 2rem 1rem

      @media #{$Medium}
        .service-title
          font-size: 1.5rem

      @media #{$Small}
        .service-title
          font-size: 1rem
      
      .why-choose
        line-height: 2rem
        padding-top: 1rem
        padding-left: 0

    .service-title
      margin-bottom: 1.5rem
      padding: 1rem 0
      font-size: 1.5rem
      font-weight: bold
      color: #202020
      font-family: 'Open Sans', sans-serif
      border-bottom: 2px solid #202020
      width: 100%

    .container
      display: flex
      align-items: center
      justify-content: space-between
      padding-bottom: 5rem

      @media only screen and (max-width: 992px )
        margin: 0

      @media #{$Large}
        padding-bottom: 1rem

    .right-about
      display: flex
      flex-direction: column
      padding: 0rem

      .about-title
        margin-bottom: 1.5rem
        padding: 1rem 0
        font-size: 2rem
        font-weight: bold
        color: #202020
        font-family: 'Open Sans', sans-serif
        border-bottom: 2px solid #202020
        width: 100%

      .description-container
        font-family: 'Lato', sans-serif
        min-width: 31.25rem

        .about-description
          font-size: 1.1rem
          color: #121212
          line-height: 2rem
          margin: 1rem 0rem
          
        ul
          padding-left: 1rem

          &:first-child
            margin-top: 0
      
    .left-about
      padding-right: 6rem
      display: flex
      align-items: flex-end

      .box-stack-img
        margin-bottom: 2rem

        @media only screen and (max-width: 1200px)
          width: 20rem
        @media only screen and (max-width: 992px )
          width: 15rem
    
    @media only screen and (max-width: 1100px)
      h1 
        font-size: 2rem
        margin-bottom: 2rem
      
    @media #{$Large}
      h1
        font-size: 2rem


    @media only screen and (max-width: 900px)
      h1 
        font-size: 1.75rem
        margin-top: 1rem
      .container
        margin: auto

      .right-about
        width: 100%

      .left-about
        display: none

    @media #{$Large}
      padding: 2rem 1rem

    @media #{$Medium}
      .right-about
        .about-title, .service-title
          font-size: 1.5rem
        .description-container
          min-width: 0
          .about-description
            font-size: 1rem

    @media #{$Small}
      padding: 1rem

      .right-about
        text-align: left

        .about-title, .service-title
          font-size: 1rem

        .description-container
          .about-description
            line-height: 2rem

  .gallery-container
    padding: 5rem
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    background-color: #dde2e4
    position: relative

    @media #{$Large}
      padding: 2rem 0

    @media #{$Medium}
      .service-title
        font-size: 1.5rem
      
      .about-description
            font-size: 1rem

    @media #{$Small}
      .service-title
        font-size: 1rem

    .gallery-title
      font-size: 2.5rem
      font-family: 'Open Sans', sans-serif
      margin-bottom: 4rem
      color: #202020
      font-weight: bold

      @media #{$Medium}
        font-size: 2rem

      @media #{$Small}
        margin-bottom: 2rem


    .photos-container
      display: flex
      flex-direction: column

      .photo-row
        display: flex
        margin-bottom: 2rem

        .photo-crop
          height: 20rem
          width: 20rem
          overflow: hidden
          transition: .5s
          display: flex
          justify-content: center
          align-items: flex-start

          &:hover
            transform: scale(1.1)
            cursor: pointer

          &:nth-child(2)
            margin: 0 2rem

          .photo
            width: 20rem

          @media only screen and (max-width: 1100px)
            width: 15rem
            height: 15rem

            .photo
              width: 15rem

    @media #{$Large}
      .photos-container
        .photo-row
          margin-bottom: 1rem

          .photo-crop
            width: 12.5rem
            height: 12.5rem

            &:nth-child(2)
              margin: 0 1rem

            .photo
              width: 12.5rem

    @media #{$Medium}
      .photos-container
        .photo-row
          margin-bottom: 1rem

          .photo-crop
            width: 9rem
            height: 9rem

            &:nth-child(2)
              margin: 0 1rem

            .photo
              width: 9rem

    @media #{$Small}
      .photos-container
        .photo-row
          margin-bottom: 1rem

          .photo-crop
            width: 7rem
            height: 7rem

            &:nth-child(2)
              margin: 0 1rem

            .photo
              width: 7rem

    @media #{$XtraSmall}
      .photos-container
        .photo-row
          .photo-crop
            width: 5.8rem
            height: 5.8rem

            .photo
              width: 5.8rem

  .contact-container
    padding-bottom: 10rem
    display: flex
    flex-direction: column
    align-items: center
    background-color: #dde2e4
    position: relative
    overflow: hidden

    .contact-title
      font-size: 3rem
      font-weight: bold
      font-family: 'Open Sans', sans-serif
      margin-bottom: 3rem
      color: #121212

    .contact-content
      display: flex
      justify-content: center
      flex-direction: column
      align-items: center
      max-width: 1024px
      width: 100%

      .phone-number
        font-size: 8rem
        color: $haBlue
        position: relative
        overflow: hidden
        transition: .25s ease
        margin-bottom: 3rem
        margin-top: 1rem
        text-shadow: 5px 5px 10px #121212
        padding: 0 1rem

        &:hover
          text-decoration: none
          color: #121212

        .p-number
          margin: 0

        @media only screen and (max-width: 992px )
          font-size: 7rem

      .hours
        font-size: 2rem
        font-family: 'Lato', sans-serif

    @media #{$Large}
      .contact-title
        font-size: 2.5rem

      .contact-content
        .phone-number
          font-size: 6rem

        .hours
          font-size: 1.8rem

    @media #{$Medium}
      .contact-title
        font-size: 2rem

      .contact-content
        .phone-number
          font-size: 4rem

        .hours
          font-size: 1.5rem

    @media #{$Small}
      padding-top: 5rem
      .contact-title
        font-size: 1.5rem
        margin-bottom: 1rem

      .contact-content
        .phone-number
          font-size: 3rem

        .hours
          font-size: 1.2rem

    @media #{$XtraSmall}
      .contact-title
        font-size: 1.3rem

      .contact-content
        .phone-number
          font-size: 2.5rem

        .hours
          font-size: 1rem

  .modal-container
    position: fixed
    z-index: 10
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(black, 0.85)
    display: flex
    align-items: center
    justify-content: center

    .wrapper
      position: relative
      display: flex

      [name="close"]
        position: absolute
        top: -2rem
        right: 1rem
        border: none
        width: 2rem
        height: 2rem
        border-radius: 5px
        background: none
        color: #fefefe

      img
        max-height: 90vh
        max-width: 75%
        margin: 0 auto
        align-self: center

  .mobile-nav-container
    display: flex
    position: fixed
    z-index: 11
    top: 0
    right: 0
    width: 100%
    flex-direction: row-reverse

    .exit-menu
      width: 20%
      height: 100vh
      background-color: rgba(0, 0, 0, 0.6)

    .menu
      width: 80%
      height: 100vh
      background-color: #121212
      box-shadow: -2px 0 15px #121212

      .link-container
        div
          display: flex
          flex-direction: column
          align-items: center
          justify-content: center
          height: 100vh

          .links
            color: #fefefe
            font-family: 'Open Sans', sans-serif
            font-size: 1.7rem
            margin-bottom: 2rem
            padding: .5rem

  .slide-in
    transform: translateX(0)
    transition: .25s ease-in

  .slide-out
    transform: translateX(100%)
    transition: .25s ease-in-out
